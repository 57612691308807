import BaseModel from './BaseModel';
import FileModel from './FileModel';
import UserModel from './UserModel';
import OptionModel from './OptionModel';
import { TaskChecklistModel } from './TaskChecklistModel';
import { taskWorkTypeOptions } from '../constants/options.js';

export default class TaskTemplateModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.description = data.description || '';
    this.category = data.category ? new OptionModel(data.category) : null;
    this.work_type = data.work_type ? taskWorkTypeOptions.find(option => option.id === data.work_type) : null;
    this.user = data.user ? new UserModel(data.user) : null;
    this.attachments = (data.attachments || []).map(f => new FileModel(f));
    this.archived = 'archived' in data ? data.archived : false;
    this.deleted = 'deleted' in data ? data.deleted : false;
    this.created = data.created ? new Date(data.created) : null;
    this.updated = data.updated ? new Date(data.updated) : null;
    this.active_task_count = data.active_task_count || 0;
    this.active_pm_schedule_count = data.active_pm_schedule_count || 0;
    this.checklists = (data.checklists || []).map(c => new TaskChecklistModel(c, { persisted: false }));
  }

  get categoryName() {
    return this.category?.name || '';
  }

  get workTypeName() {
    return this.work_type?.display_label || '';
  }

  get creatorName() {
    return this.user ? this.user.fullName : '';
  }

  get checklistCount() {
    return this.checklists.length;
  }
}
