import Vue from 'vue';
import moment from 'moment';

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export default {
  dateOnly(d, isUTC = false) {
    if (!d) {
      return Vue.prototype.$globals.defaultDisplayValue;
    }

    if (isUTC) {
      return moment(d).utc().format('M/D/YYYY');
    }

    const d1 = (d && d.toLocaleDateString) ? d : new Date(d);
    return d1.toLocaleDateString();
  },
  booleanDisplay(b) {
    if (b === null) return Vue.prototype.$globals.defaultDisplayValue;

    return b ? 'Yes' : 'No';
  },
  capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.split('_').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ');
  },
  datastr(val) {
    let bytes = parseInt(val);
    if (bytes) {
      const thresh = 1000;
      const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
      }

      let u = -1;
      do {
        bytes /= thresh;
        ++u;
      } while (Math.abs(bytes) >= thresh && u < units.length - 1);
      return `${bytes.toFixed(1)} ${units[u]}`;
    }
    return '';
  },
  number(value, precision = 0, absoluteValueWhenPrecisionZero = true) {
    if (value == null) {
      return null;
    }
    if (!value) {
      return '0';
    }
    if (precision === 0 && absoluteValueWhenPrecisionZero) {
      value = Math.abs(value); // This is an artifact of how this filter used to function; see history.
    }
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: precision }).format(value);
  },
  humanizedNumber(value, includeGt = false) {
    const units = ['', 'K', 'M', 'B', 'T'];
    let wasFloored = false;
    let numString = value;
    let unitIndex = 0;
    while (parseInt(numString) >= 1000) {
      numString = (parseInt(numString) / 1000).toString().replace(/\.$/, '');
      unitIndex++;
    }

    let shortNum = parseFloat((unitIndex != 0 ? (value / 1000 ** unitIndex) : value));

    if (shortNum % 1 != 0) {
      shortNum = Math.floor(shortNum);
      wasFloored = true;
    }

    return (includeGt && wasFloored ? '>' : '') + shortNum + units[unitIndex];
  },
  currency(value, precision = 2) {
    if (value === null) return null;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
    return formatter.format(value);
  },
  timeOnly(d) {
    return (d ? d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '');
  },
  dateMonthWithYear(d) {
    return (d ? `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}` : '');
  },
  dateLongMonthNoYear(d) {
    return (d ? `${monthNames[d.getMonth()]} ${d.getDate()} at ${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}` : '');
  },
  dateShort(d) {
    return (d ? moment(d).format('MMM D, YYYY') : '');
  },
  dateShortHideCurrentYear(d) {
    if (!d || typeof d.getFullYear !== 'function') return '';
    if (d.getFullYear() === new Date().getFullYear()) {
      return moment(d).format('MMM D');
    }
    return this.dateShort(d);
  },
  dateNoSeconds(d) {
    if (!d) {
      return Vue.prototype.$globals.defaultDisplayValue;
    }

    if (typeof d === 'string') {
      d = new Date(d);
    }

    return (d ? `${d.toLocaleDateString()}, ${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}` : '');
  },
  dateUTC(d) {
    return (d ? d.toLocaleString('en-US', { timeZone: 'UTC' }) : '');
  },
  date(d) {
    return (d ? d.toLocaleString() : '');
  },
};
