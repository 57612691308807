import {
  faSpinner,
  faBullhorn,
  faUnlink,
  faFireExtinguisher,
  faThermometerHalf,
  faDesktop,
  faTruck,
  faTrash as faTrashSolid,
  faFan,
  faAddressBook,
  faBook,
  faSort,
  faSortDown,
  faSortUp,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faStar,
  faHome,
  faComments,
  faCog,
  faChartBar,
  faThLarge,
  faThList,
  faBell,
  faBolt,
  faHeart,
  faMedal,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
  faEllipsisV,
  faCheck,
  faFile,
  faFilter,
  faShareSquare,
  faUsers,
  faClock,
  faShieldAlt,
  faLock,
  faUserPlus,
  faAlignLeft,
  faUsersCog,
  faClipboardListCheck,
  faTimes as faTimesBold,
  faSlidersUp,
  faRepeat,
  faPencil,
  faExclamationTriangle,
  faThumbsUp,
  faThumbsDown,
  faEnvelopeSquare,
  faPhoneSquare,
  faUsdSquare,
  faBoxOpen,
  faInbox,
  faUser,
  faSignOutAlt,
  faSignInAlt,
  faUpload,
  faHistory,
  faCaretDown,
  faCaretRight,
  faMousePointer,
  faRandom,
  faUserFriends,
  faUserCheck,
  faUserClock,
  faUserLock,
  faDollarSign,
  faCloud,
  faCloudRain,
  faCloudSleet,
  faCloudSnow,
  faClouds,
  faSmog,
  faWind,
  faSun,
  faHurricane,
  faDownload,
  faFileUpload,
  faSearch as faSearchBold,
  faBars as faBarsBold,
  faMapMarkerAlt,
  faImages,
  faUniversity,
  faCreditCard,
  faTools as faToolsSolid,
  faUserHardHat,
  faFolderOpen,
  faArchive,
  faQrcode,
  faCopy,
  faTags,
  faTag,
  faCopyright,
  faStore,
  faJoystick,
  faUndo,
  faClipboardList,
  faCauldron,
  faOven,
  faFaucetDrip,
  faEllipsisH,
  faListAlt,
  faPhoneRotary,
  faMobileAlt,
  faFax,
  faVolumeSlash,
  faVolumeUp,
  faSignOut,
  faArrowRight,
  faFlag,
  faCalendar as faCalendarSolid,
  faCalendarPlus,
  faGauge,
  faGripVertical,
  faExchange,
  faLightbulb,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faFillDrip,
  faBlog,
  faGift,
  faImage,
  faVideo,
  faPlay,
  faStop,
  faBadgeCheck,
  faInfoCircle as faInfoCircleSold,
  faRouter,
  faChartColumn,
  faCircleExclamation,
  faSquareCheck,
  faCircleDollar,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faBars,
  faTimes,
  faEnvelope,
  faPlus,
  faBox,
  faCalendarAlt,
  faTools,
  faTasks,
  faMapMarkedAlt,
  faShoppingCart,
  faLink,
  faCommentsAlt,
  faBuilding,
  faReceipt,
  faChartLine,
  faUserTie,
  faSearch,
  faAward,
  faEye,
  faWrench,
  faCogs,
  faChargingStation,
  faFileContract,
  faFileAlt,
  faPaperclip,
  faTrash,
  faUserCircle,
  faSlidersUp as faSlidersA,
  faSyncAlt,
  faFlag as faFlagRegular,
  faCheck as faCheckRegular,
  faFileExport,
  faExpandArrowsAlt,
  faTimesCircle as faTimesCircleRegular,
  faClipboardListCheck as faClipboardListCheckRegular,
  faChevronRight as faChevronRightRegular,
  faChevronLeft as faChevronLeftRegular,
  faChevronDown as faChevronDownRegular,
  faChevronUp as faChevronUpRegular,
  faCircle as faCircleRegular,
  faBell as faBellRegular,
  faComment,
  faBinoculars,
  faFilePdf,
  faList,
  faPencil as faPencilRegular,
  faUserTimes,
  faBan,
  faFileInvoice,
  faStopwatch,
  faDownload as faDownloadRegular,
  faStar as faStarRegular,
  faCopy as faCopyRegular,
  faCalendarPlus as faCalendarPlusRegular,
  faCalendarLinesPen as faCalendarLinesPenRegular,
  faCamera,
  faBatteryQuarter,
  faBatteryHalf,
  faBatteryFull,
  faSave,
  faDiagramSubtask,
  faFolderGear,
  faCalendarClock,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faCircle,
  faInfoCircle,
  faFolderOpen as faFolderOpenLight,
  faLayerPlus,
} from '@fortawesome/pro-light-svg-icons';

import {
  faFacebook,
  faXTwitter,
  faLinkedinIn,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

const fontAwesomeIcons = [
  faUnlink,
  faBars,
  faBarsBold,
  faTimes,
  faBook,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faSearch,
  faSearchBold,
  faStar,
  faStarRegular,
  faHome,
  faComments,
  faCog,
  faChartLine,
  faThLarge,
  faThList,
  faBell,
  faFacebook,
  faXTwitter,
  faLinkedinIn,
  faEnvelope,
  faBolt,
  faHeart,
  faEye,
  faMedal,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
  faPlus,
  faEllipsisV,
  faCheck,
  faFile,
  faFilter,
  faShareSquare,
  faUsers,
  faClock,
  faShieldAlt,
  faLock,
  faUserPlus,
  faAlignLeft,
  faAddressBook,
  faUsersCog,
  faClipboardListCheck,
  faTools,
  faBuilding,
  faTimesBold,
  faSlidersUp,
  faRepeat,
  faPencil,
  faExclamationTriangle,
  faThumbsUp,
  faThumbsDown,
  faEnvelopeSquare,
  faPhoneSquare,
  faUsdSquare,
  faBoxOpen,
  faInbox,
  faUser,
  faSignOutAlt,
  faSignInAlt,
  faUpload,
  faHistory,
  faCaretDown,
  faCaretRight,
  faMousePointer,
  faRandom,
  faUserFriends,
  faUserCheck,
  faUserClock,
  faChevronLeft,
  faChartBar,
  faBox,
  faBadgeCheck,
  faCalendarAlt,
  faTasks,
  faMapMarkedAlt,
  faShoppingCart,
  faLink,
  faCommentsAlt,
  faBuilding,
  faReceipt,
  faUserTie,
  faUserLock,
  faDollarSign,
  faCloud,
  faCloudRain,
  faCloudSleet,
  faCloudSnow,
  faClouds,
  faSmog,
  faWind,
  faSun,
  faHurricane,
  faDownload,
  faFileUpload,
  faCircle,
  faInfoCircle,
  faMapMarkerAlt,
  faImages,
  faAward,
  faCogs,
  faChargingStation,
  faFileContract,
  faFileAlt,
  faPaperclip,
  faWrench,
  faUniversity,
  faCreditCard,
  faToolsSolid,
  faUserHardHat,
  faFolderOpen,
  faArchive,
  faQrcode,
  faBullhorn,
  faTrash,
  faTrashSolid,
  faCopy,
  faCopyRegular,
  faTags,
  faTag,
  faCopyright,
  faStore,
  faJoystick,
  faUndo,
  faClipboardList,
  faInstagram,
  faCauldron,
  faFireExtinguisher,
  faThermometerHalf,
  faOven,
  faFaucetDrip,
  faDesktop,
  faTruck,
  faEllipsisH,
  faFan,
  faListAlt,
  faPhoneRotary,
  faMobileAlt,
  faFax,
  faVolumeSlash,
  faVolumeUp,
  faSignOut,
  faArrowRight,
  faSlidersA,
  faUserCircle,
  faFlag,
  faCalendarSolid,
  faCalendarPlus,
  faCalendarPlusRegular,
  faCalendarLinesPenRegular,
  faGauge,
  faSyncAlt,
  faFlagRegular,
  faCheckRegular,
  faFileExport,
  faExpandArrowsAlt,
  faTimesCircleRegular,
  faClipboardListCheckRegular,
  faGripVertical,
  faChevronRightRegular,
  faChevronLeftRegular,
  faChevronDownRegular,
  faChevronUpRegular,
  faCircleRegular,
  faBellRegular,
  faComment,
  faExchange,
  faLightbulb,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faFillDrip,
  faGift,
  faBlog,
  faBinoculars,
  faFilePdf,
  faImage,
  faVideo,
  faPlay,
  faList,
  faPencilRegular,
  faUserTimes,
  faBan,
  faFileInvoice,
  faStop,
  faStopwatch,
  faDownloadRegular,
  faInfoCircleSold,
  faCamera,
  faFolderOpenLight,
  faLayerPlus,
  faRouter,
  faBatteryQuarter,
  faBatteryHalf,
  faBatteryFull,
  faSave,
  faChartColumn,
  faDiagramSubtask,
  faFolderGear,
  faCalendarClock,
  faCircleExclamation,
  faSquareCheck,
  faCircleDollar,
];

export default fontAwesomeIcons;
