import moment from 'moment';
import BaseModel from './BaseModel';
import filters from '../filters';

export default class EquipmentFieldModel extends BaseModel {
  static FieldType = Object.freeze({
    String: 'string',
    Boolean: 'boolean',
    Integer: 'int',
    Decimal: 'float',
    Currency: 'currency',
    Date: 'date',
    Select: 'select',
  });

  init(data) {
    this.id = data.id || 0;
    this.label = data.label || '';
    this.field_type = data.field_type || '';
    this.select_options = data.select_options || [];
    this.value = data.value || null;
  }

  get computedId() {
    return `category_specific_field:${this.id}`;
  }

  get value() {
    return this._value;
  }

  set value(val) {
    // TODO: logic per field type?
    if (val && typeof val !== 'string') {
      val = val.toString();
    }
    this._value = val;
  }

  get valueTyped() {
    if (this.field_type === EquipmentFieldModel.FieldType.Integer) {
      return this.valueInt;
    }

    if ([EquipmentFieldModel.FieldType.Decimal, EquipmentFieldModel.FieldType.Currency].includes(this.field_type)) {
      return this.valueFloat;
    }

    if (this.field_type === EquipmentFieldModel.FieldType.Date) {
      return this.valueDate || '';
    }

    return this._value || '';
  }

  get valueForDisplay() {
    if (this.field_type === EquipmentFieldModel.FieldType.Boolean) {
      return this.value ? filters.booleanDisplay(this.valueBoolean) : null;
    }

    if (this.field_type === EquipmentFieldModel.FieldType.Currency) {
      return this.value ? filters.currency(this.value) : null;
    }

    if (this.field_type === EquipmentFieldModel.FieldType.Date) {
      return this.value ? filters.dateOnly(this.valueDate) : null;
    }

    if (this.field_type === EquipmentFieldModel.FieldType.Decimal) {
      return this.value ? filters.number(this.value, 2) : null;
    }

    if (this.field_type === EquipmentFieldModel.FieldType.Integer) {
      return this.value ? filters.number(this.value) : null;
    }

    return this.value;
  }

  get valueBoolean() {
    return this._value === 'true';
  }

  get valueInt() {
    return parseInt(this._value);
  }

  get valueFloat() {
    return parseFloat(this._value);
  }

  get valueDate() {
    if (!this._value) return null;
    return moment(this._value).toDate();
  }
}
